.text-center {
  text-align: center;
}

.column-center {
  align-items: 'center';
  display: flex;
  flex-direction: column;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}
