.row {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 8px;
}

.row:hover {
  -moz-box-shadow: inset 0 0 10px#63e0ff;
  -webkit-box-shadow: inset 0 0 10px#63e0ff;
  box-shadow: inset 0 0 10px#63e0ff;
}

.cell {
  align-items: center;
  display: flex;
}

.cell-clickable {
  @extend .cell;
}

.cell-clickable:hover {
  cursor: pointer;
}

.badge {
  align-items: center;
  background-color: #63e0ff40;
  border: 2px solid #63e0ff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  padding: 4px;
}

.badge > span {
  text-align: center;
  font-size: x-small;
}
