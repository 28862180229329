.control-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  &-hidden {
    @extend .control-container;
    display: none;
  }
}

.control-title {
  width: 10%;
}
